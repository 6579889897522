@import "../Register/Register";
@import "../../styles/colors.scss";

.div-main-reset-password{
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  .div-password {
    position: relative;
    margin: 1.2em 0 1.2em 0;

    .input-login {
      margin: 0;
    }

    #icon-eye-see-password {
      @include positioningEyeIcon();
    }
  }
}
