$dark-moss-green: #606c38ff;
$pakistan-green: #283618ff;
$cornsilk: #fefae0ff;
$earth-yellow: #dda15eff;
$tigers-eye: #bc6c25ff;

$background-color: #f2f0ec;
$liens: #f73f24;
$secondary-color: #22242a;
$color-border: #909091;
$idee-color: #476542;