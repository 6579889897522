body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img,
button,
select,
option {
  cursor: pointer;
}

button {
  border-radius: 5px;
  background-color: #22242A;
  color: #ffffff;
  padding: 1em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.425s ease-in-out;
}

.overflow-hidden {
  overflow-y: hidden;
}

button:hover:hover {
  background-color: #ded2b2;
  color: #000000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

h2 {
  font-weight: bold;
}

a:link {
  color: #000000;
  text-decoration: none;
}

a:visited {
  color: #000000;
  text-decoration: none;
}

html {
  overflow-x: hidden;
}

.div-btn-signOut {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em 0 2em 0;
}

