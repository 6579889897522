.leaflet-container {
  @media (min-width: 1300px) {
    height: 100dvh;
  }
  height: 50dvh;
  width: 50dvw;
  @media (max-width: 800px) {
    height: 75dvh;
    width: 95dvw;
  }
}

.div-popup-marker {
  p {
    margin: 0.4em;
  }
}