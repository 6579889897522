@import "../../styles/colors.scss";

$image-1: "https://res.cloudinary.com/dc1p20eb2/image/upload/v1730896590/Anne%27so%20Naturelle/Image/bracelet_main_page.jpg";
$image-zen: "https://res.cloudinary.com/dc1p20eb2/image/upload/v1731944535/Anne%27so%20Naturelle/Image/kiimtexnezpkhhwyzo1h.webp";
$image-bijoux: "https://res.cloudinary.com/dc1p20eb2/image/upload/v1731944875/Anne%27so%20Naturelle/Image/ar0whsyzklscqyw1uyba.webp";
$image-bien-etre: "https://res.cloudinary.com/dc1p20eb2/image/upload/v1736624047/Anne%27so%20Naturelle/Image/xxwoqioq5jovziujmo6x.webp";

.first-page-accueil-body {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: end;

  .div-first-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    &.image-1 {
      background-image: url("#{$image-1}");
      background-size: cover;
      background-position: center;
    }

    &.image-zen {
      background-image: url("#{$image-zen}");
      background-size: cover;
      background-position: center;
    }

    &.image-bijoux {
      background-image: url("#{$image-bijoux}");
      background-size: cover;
      background-position: center;
    }

    &.image-bien-etre {
      background-image: url("#{$image-bien-etre}");
      background-size: cover;
      background-position: center;
    }

    background-size: cover;
    object-fit: cover;

    h2, h1 {
      text-align: center;
      margin: 3em 0 0 0;
      font-family: Playfair Display, serif;
    }
  }

  .div-categories-1 {
    display: flex;
    align-items: center;
    margin: 1em 0 1em 0;
    flex-direction: row;
    @media (max-width: 1300px) {
      flex-direction: column;
    }

    p {
      margin: 1em 0 0 0;
    }
  }

  .div-example-of-products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 3em 0;
  }

  .background-text-accueil {
    background-color: $tigers-eye;
    border-radius: 20px;
    padding: 2em;
    display: flex;
    justify-content: space-between;
  }

  .img_accueil {
    z-index: 2;
    border-radius: 225.275px 225.275px 0px 0px;
    background-image: url("../../Images/Rectangle 7.png");
  }

  .text-div-accueil {
    display: flex;
    flex-direction: column;
  }

  .braceletAccueil {
    transform: rotate(45deg);
  }
}