@import "../../styles/colors.scss";

.body-element-panier {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 5em 0 5em 0;
  @media (max-width: 1330px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .panier-check-out {
    position: sticky;
    top: 9%;
    padding: 0 0 0 5em;
    height: 100%;
    width: 25%;
    @media (max-width: 1330px) {
      padding: 0;
      width: 90%;
    }

    button {
      width: 100%;
    }

    .div-summary-command {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .div-1-element-panier {
    display: flex;
    flex-direction: row;
    margin: 0 0 3em 0;
    border: 2px solid black;
    padding: 3em;
  }

  .div-1-element-panier > select {
    margin: 0 3em 0 1em;
  }

  .div-panier-vide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5em;
    height: 35vh;
  }

  .panier {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 2em 0;
    margin: 0 0 0 2em;
    @media (max-width: 1330px) {
      width: 95%;
      margin: 0;
    }

    .div-main-code-promo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1em 0 0 0;
      @media (max-width: 930px) {
        flex-direction: column;
      }

      .div-code-promo-left {
        margin: 1em 0 0 0;
        max-width: 25em;
        @media (max-width: 1330px) {
          margin: 1em 0 1.5em 0;
        }

        :hover {
          cursor: pointer;
        }

        #span-code-promo {
          padding: 1em 1em 0 1em;
        }
      }

      .div-code-promo-right {
        max-height: 1em;
        @media (max-width: 1330px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        button {
          margin: 0 0 0 0.25em;
        }
      }
    }

    #title-panier {
      padding: 0 0 0 1em;
    }
  }

  #btn-vider-panier {
    margin: 0 0 4em 0;
  }

  select {
    border-radius: 10px;
    padding: 0.5em;
  }

  .div-panier-vide {
    h2 {
      min-width: 7em;
      text-align: center;
    }
  }
}
