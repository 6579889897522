@import "../../styles/colors.scss";

@mixin positioningEyeIcon() {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.div-input-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0.40em 0;
  width: 95%;

  label {
    text-align: start;
  }

  .div-error-message-register {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9em;
    margin: 0.5em 0 0 0;

    p {
      margin: 0;
    }

    #icon-infos-error {
      margin: 0 0.5em 0 0;
      color: red;
    }
  }

  .div-password {
    position: relative;

    #icon-eye-see-password {
      @include positioningEyeIcon();
    }
  }
}

.body-element-register {
  padding: 2em 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $background-color;
  height: 90vh;

  p {
    padding: 0;
    margin: 0 0 0.5em 0;
  }

  input {
    margin: 0;
    width: 95%;
    @media (max-width: 350px) {
      width: 92%;
    }
  }

  .btn-genre {
    padding: 0.7em;
    width: auto;
    margin: 1em 1em 2em 0;
  }

  .div-text-login {
    margin: 0 0 2em 0;
  }

  .div-label-register {
    display: flex;
    align-items: flex-start;
    justify-content: start;
  }
}

.div-login-right {
  display: flex;
  justify-content: center;
  width: 50%;
  max-height: 100vh;
  object-fit: cover;

  img {
    width: 100%;
    border-radius: 5px 5px 5px 0px;
  }

  @media (max-width: 1300px) {
    display: none;
  }
}

.div-login-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn-google-login {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    width: 30%;
    margin: 1.75em 0 1.75em 0;
    color: #000;
    @media (max-width: 1300px) {
      width: 60%;
    }
    @media (min-width: 500px) and (max-width: 900px) {
      width: 70%;
    }
    @media (min-width: 901px) and (max-width: 1300px) {
      width: 50%;
    }
  }

  @media (max-width: 499px) {
    width: 100%;
  }

  .div-connexion {
    padding: 1em;
    display: flex;
    flex-direction: column;

    label {
      text-align: start;
    }

    .div-password {
      position: relative;

      .input-login {
        margin: 0;
      }

      #icon-eye-see-password {
        @include positioningEyeIcon();
      }
    }
  }
}
