@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-div-navigation-products {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  @media (max-width: 1300px) {
    display: none;
  }

  .div-category-secondary {
    p {
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: #4C6945; // Couleur de la barre de soulignement
        transition: width 0.3s;
      }

      &:hover::after {
        width: 100%;
      }
    }

    visibility: hidden;
    background-color: #f8f9fa; // Couleur de fond claire
    padding: 1em;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); // Ombre pour donner un effet de profondeur
    transition: visibility 0.3s, opacity 0.3s linear; // Transition douce lors de l'apparition
  }

  .div-category-first-and-secondary {
    margin: 0 2em 0 0;

    h3 {
      padding: 0.5em;
      font-size: 1.2em;
      border-radius: 5px;
      margin-bottom: 0.5em;
    }

    &:hover {
      .div-category-secondary {
        visibility: visible;
        cursor: pointer;
        animation: fadeIn 0.5s;
      }
    }
  }

  .div-category-first-and-secondary:hover {
    cursor: pointer;

    .div-category-secondary {
      visibility: visible;
      cursor: pointer;
      animation: fadeIn 0.5s;
    }
  }
}

.main-div-navigation-products-mobile {
  display: none;

  p, h4 {
    cursor: pointer;
  }

  .div-main-category-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h4 {
      margin: 0 1em 0.5em 0;
    }
  }

  .div-header-secondary-category-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  .div-secondary-category-mobile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1em 0 1em 0;

    p {
      margin: 0.5em 0.5em 0 0;
      background-color: #ECF3F8;
      padding: 0.5em;
      border-radius: 7px
    }
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}