.main-div-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 2em;
}
.main-div-categories .header-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.25em 0.5em;
}
.main-div-categories .categories-div {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 1.5px solid #909091;
  min-width: 15em;
  margin: 0 0 0.6em 0;
}
.main-div-categories .categories-div :hover {
  cursor: pointer;
}
.main-div-categories .categories-div .header-categories {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.5em;
}
.main-div-categories .categories-div .div-body-filter-card .div-une-pierre input {
  width: 14px;
  height: 14px;
}
.main-div-categories .categories-div .radio-trie-div {
  margin: 0 0 0.5em 0;
}
.main-div-categories .categories-div .radio-trie-div input {
  margin: 0 0.8em 0 1em;
}
.main-div-categories .div-max-and-min-filter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-div-categories .div-max-and-min-filter input {
  max-width: 4em;
  margin: 0 1.5em 0 1.5em;
  border-radius: 5px;
  padding: 0.2em;
}
.main-div-categories .slider-div {
  margin: 1em;
  display: flex;
  flex-direction: column;
}
.main-div-categories .slider-div #btn-valider-filtre-prix {
  margin: 1em 0 0 0;
}
.main-div-categories span {
  margin: 0.5em 2em 1em 0;
  min-width: 7em;
}
.main-div-categories a {
  padding-bottom: 1em;
}
.main-div-categories .icon-categories {
  cursor: pointer;
}

