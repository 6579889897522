.main-div-checkout-payment {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5em;
  @media (max-width: 1300px) {
    flex-direction: column;
    margin: 1em;
  }

  .div-secondary-details-products {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid black;
    padding: 1em;
    width: 100%;

    @media (max-width: 400px) {
      padding: 0.4em 0 0.4em 0.4em;
    }
  }
}