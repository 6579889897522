@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.body-lock-scroll {
  overflow: hidden;
}

.div-button-filtrer-trier {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.9em;
  border-radius: 8px;
  background-color: #F2F2F5;
  margin: 1em 0 0 0;

  p {
    margin: 0 0 0 1em;
  }

  &:hover {
    cursor: pointer;
    background-color: #040037;
    color: #ffffff;
  }
}

.main-div-filtre-et-trie {
  width: 30%;
  height: 100vh;
  z-index: 88;
  position: fixed;
  right: 0;
  background-color: #ffffff;
  animation: slideInFromRight 0.5s forwards;
  border-radius: 10px 0 0 10px;
  @media (max-width: 768px) {
    width: 100%; // Prend tout l'écran sur les petits écrans
    border-radius: 20px 20px 0 0;
  }

  .div-trie {
    display: flex;
    flex-direction: column;
    padding: 1em 1em 0 1em;
    font-size: 1.01em;

    input {
      margin: 0 1em 0 0;
      transform: scale(1.1);
    }
  }

  .slider-div {
    margin: 1em;
    display: flex;
    flex-direction: column;

    #btn-valider-filtre-prix {
      margin: 1em 0 0 0;
    }
  }

  .div-max-and-min-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 1em;

    input {
      max-width: 4em;
      margin: 0 1.5em 0 1.5em;
      border-radius: 5px;
      padding: 0.20em;
    }
  }
}