body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img,
button,
select,
option {
  cursor: pointer;
}

button {
  border-radius: 5px;
  background-color: #22242A;
  color: #ffffff;
  padding: 1em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.425s ease-in-out;
}

.overflow-hidden {
  overflow-y: hidden;
}

button:hover:hover {
  background-color: #ded2b2;
  color: #000000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

h2 {
  font-weight: bold;
}

a:link {
  color: #000000;
  text-decoration: none;
}

a:visited {
  color: #000000;
  text-decoration: none;
}

html {
  overflow-x: hidden;
}

.body-page-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 0 2.5em 0;
  font-size: clamp(16px, 5vw, 24px);
}
.body-page-contact .input-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}
.body-page-contact .input-contact > input {
  padding: 0.8em;
  border: 2px solid #909091;
  width: 30em;
  border-radius: 5px;
  margin: 0 0 2em 0;
}
@media (max-width: 570px) {
  .body-page-contact .input-contact > input {
    max-width: 70%;
  }
}
.body-page-contact textarea {
  border: 2px solid #909091;
  border-radius: 5px;
  height: 25vh;
  padding: 0.5em;
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-size: 1em;
  width: 95%;
}
@media (max-width: 570px) {
  .body-page-contact textarea {
    max-width: 70%;
  }
}
.body-page-contact .images {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 570px) {
  .body-page-contact .images #img_cactus_contact_page {
    display: none;
  }
}
@media (max-width: 570px) {
  .body-page-contact .images #img_dame {
    display: none;
  }
}
.body-page-contact #btn_contact {
  margin: 1em 0 0 0;
  padding: 1em;
  width: 100%;
}
@media (max-width: 570px) {
  .body-page-contact #btn_contact {
    max-width: 78%;
  }
}

@media (max-width: 1100px) {
  .body-page-contact {
    flex-direction: column-reverse;
  }
}

