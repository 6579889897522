.swal-full-height {
  height: 100vh !important;
  background-color: red;
  overflow: hidden !important;
  padding: 0 !important;
}

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  @media (min-width: 1000px) {
    width: 30%;
  }
}

.custom-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0.5em;
  cursor: pointer;
  color: #000;
}

.hr-toast-add-to-basket {
  border-top: 1px solid #CFCFD0;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slideIn {
  animation: slideIn 0.3s forwards;
}

.slideOut {
  animation: slideOut 0.3s forwards;
}

@media (max-width: 1000px) {
  .swal-full-height {
    min-width: 100vw !important;
    height: 100vh !important;
    bottom: 0 !important;
    padding: 0 !important;
  }

  @keyframes slideInMobile {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideOutMobile {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .slideInMobile {
    animation: slideInMobile 0.3s forwards;
  }

  .slideOutMobile {
    animation: slideOutMobile 0.3s forwards;
  }
}