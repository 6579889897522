@import "../../styles/colors.scss";

.div-commande {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  margin: 1em 0 1em 0;
  width: 100%;
  border-radius: 11px;
  @media (max-width: 1300px) {
    max-width: 95%;
  }
  @media (max-width: 440px) {
    flex-direction: column;
  }

  h4 {
    @media (max-width: 440px) {
      margin: 0.5em 0 1em 1em;
    }
  }
}

.div-status-commande {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0.5em 0;

  p {
    border-radius: 5px;
    padding: 0.25em;
    margin: 0;
  }

  @media (max-width: 440px) {
    margin: 0 0 1em 1em;
  }
}

.container-left,
.container-right {
  display: flex;
  flex-direction: column;

  h4 {
    margin: 0 0 0.5em 0;
    @media (max-width: 440px) {
      margin: 0 0 0.7em 1em;
    }
  }

  img {
    border-radius: 9px 0 0 9px;
    height: 100%;
    object-fit: cover;
    @media (max-width: 440px) {
      height: 50%;
      width: 100%;
    }
  }
}

.container-right {
  margin: 1em 0 0 0;
  @media (min-width: 441px) {
    margin: 1em 1em 0 0;
  }
}
