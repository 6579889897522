body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img,
button,
select,
option {
  cursor: pointer;
}

button {
  border-radius: 5px;
  background-color: #22242A;
  color: #ffffff;
  padding: 1em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.425s ease-in-out;
}

.overflow-hidden {
  overflow-y: hidden;
}

button:hover:hover {
  background-color: #ded2b2;
  color: #000000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

h2 {
  font-weight: bold;
}

a:link {
  color: #000000;
  text-decoration: none;
}

a:visited {
  color: #000000;
  text-decoration: none;
}

html {
  overflow-x: hidden;
}

#xmark-filtre-mobile {
  margin: 0.2em 0 0 0;
  position: relative;
  top: 11%;
  left: 95%;
}

.categories-div-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.categories-div-mobile button {
  margin: 0.5em 0 2em 0;
  padding: 0.5em;
}

.lmj-shopping-list {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lmj-shopping-list .div-recherche-produit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 10em;
}
.lmj-shopping-list .div-recherche-produit .search-bar-class {
  font-size: 1.5em;
  background-color: #282e32;
  padding: 0.3em;
  border-radius: 0 9px 9px 0;
  margin: 0 5em 0 0;
  border: 1px solid black;
  color: #6e7a85;
}
.lmj-shopping-list .div-recherche-produit .search-bar-class :hover {
  cursor: pointer;
}
.lmj-shopping-list .div-recherche-produit input {
  width: 27%;
  border-radius: 9px 0 0 9px;
  padding: 0.5em;
  font-size: 1.05rem;
}
.lmj-shopping-list .div-button-filtre-mobile-vue {
  display: none;
}
@media (max-width: 850px) {
  .lmj-shopping-list .div-button-filtre-mobile-vue {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1em 0 0 0;
  }
  .lmj-shopping-list .div-button-filtre-mobile-vue button {
    width: 70%;
  }
}
.lmj-shopping-list .div-categories-plus-products-list {
  display: flex;
  flex-direction: column;
  margin: 0 0 2em 0;
}
.lmj-shopping-list .div-categories-plus-products-list .categories-and-filtre-and-trie {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0 0 2em;
  position: sticky;
}
@media (max-width: 850px) {
  .lmj-shopping-list .div-categories-plus-products-list .categories-and-filtre-and-trie {
    display: none;
  }
}
.lmj-shopping-list .div-categories-plus-products-list .categories-and-filtre-and-trie a {
  margin: 0 0.75em 0 0.75em;
  font-size: 1.25rem;
  transition: 0.25s ease-in-out;
}
.lmj-shopping-list .div-categories-plus-products-list .categories-and-filtre-and-trie a:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}
.lmj-shopping-list .container-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lmj-shopping-list .hr-collections {
  border-style: solid; /* Style de la ligne */
}

.lmj-plant-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}
@media (max-width: 1300px) {
  .lmj-plant-list {
    margin: 2em 0 0 0;
  }
}
.lmj-plant-list .div-product :hover {
  box-shadow: #22242a;
}
.lmj-plant-list .button-add-to-basket-plus-heart {
  display: flex;
  align-items: center;
}

.loader-div-shopping-list {
  margin: auto;
}

#btn-ajouter {
  margin: 0 0 0 0.7em;
  width: 72%;
  padding: 0.25em;
}

#btn-details-plant {
  margin: 0 0 0 1em;
}

.hr-custom {
  margin: 0 2em 0 0;
  padding: 0;
}
@media (max-width: 850px) {
  .hr-custom {
    margin: 0 0 1em 0;
  }
}

.hr-horizontal-categories {
  margin: 0 2em 0 0;
  padding: 0;
  width: 100%;
}

