.div-text-aPropos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 300;
  padding: 0 0 2em 0;
}
@media (max-width: 1625px) {
  .div-text-aPropos #fleche {
    display: none;
  }
}
.div-text-aPropos .a-propos-texte {
  margin: 0 0 0 3em;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1330px) {
  .div-text-aPropos .a-propos-texte {
    flex-direction: column;
    margin: 1em;
  }
}
.div-text-aPropos .a-propos-texte #text-1, .div-text-aPropos .a-propos-texte #text-2, .div-text-aPropos .a-propos-texte #text-3, .div-text-aPropos .a-propos-texte #text-4, .div-text-aPropos .a-propos-texte #text-5, .div-text-aPropos .a-propos-texte #text-6, .div-text-aPropos .a-propos-texte #text-7, .div-text-aPropos .a-propos-texte #text-8 {
  width: 100%;
}
.div-text-aPropos .a-propos-texte .container-right-text-a-propos {
  margin: 5em 0 0 5em;
}
.div-text-aPropos .a-propos-texte .container-right-text-a-propos p {
  margin: 5em 0 0 0;
}
@media (max-width: 1330px) {
  .div-text-aPropos .a-propos-texte .container-right-text-a-propos {
    margin: 0;
  }
}
.div-text-aPropos .a-propos-texte .container-left-text-a-propos p {
  margin: 5em 0 0 0;
}
.div-text-aPropos .a-propos-texte .div-fleches {
  display: flex;
  flex-direction: column;
}
.div-text-aPropos .a-propos-texte .div-fleches img {
  margin: 8em 0 0 3em;
}
.div-text-aPropos .a-propos-div-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

