@import "../../styles/colors.scss";

.div-product {
  @media (max-width: 800px) {
    margin: 0 0 2em 0;
  }
  margin: 0 0 1em 0;
}

.lmj-plant-item-cover {
  width: 100%;
  height: 90%;
  border-radius: 10px 10px 0 0;
}

.lmj-plant-item {
  margin: 8px;
  margin-top: 2em;
  display: flex;
  width: 30em;
  height: 30em;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  object-fit: cover;

  @media (max-width: 800px) {
    width: 95%;
    height: 100%;
  }

  .product-item-infos {
    padding: 0.5em 0 0.5em 1em;
    margin: 0.5em 0 0.25em 0;
  }

  .container-rigt-infos-product {
    padding: 0.5em 1em 0.5em 0;
  }
}
