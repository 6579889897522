@import "../../styles/colors.scss";
@import "../Register/Register.scss";

.div-main-details-adresse {
  display: flex;
  flex-direction: row;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.div-infos-adresse {
  display: flex;
  flex-direction: column;

  .btn-save {
    padding: 0.7em;
    max-width: 20em;
    display: flex;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .infos-details-adresse {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 5em;
    @media (max-width: 1300px) {
      flex-direction: column;
      margin: 0;
      align-items: center;
    }

    .container-adresse-left,
    .div-infos-perso {
      display: flex;
      flex-direction: column;
      margin: 1em 2em 0 0;
      @media (max-width: 1300px) {
        margin: 0;
      }
    }
  }
}

#form1 {
  margin: 2em 0 2em;
}
