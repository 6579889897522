@import "../../styles/colors.scss";
@import "../../styles/index.scss";

.div-main-page-adresses {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin: 5em 0 0 0;
  @media (max-width: 1300px) {
    flex-direction: column;
    margin: 2em 0 0 0;
  }

  .div-container-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    @media (max-width: 1300px) {
      width: 100%;
    }

    #btn-add-adress {
      width: 30%;
      @media (max-width: 600px) {
        width: 80%;
      }
    }

    button {
      padding: 0.5em;
    }

    @media (max-width: 1300px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 2em 0;
    }
  }
}
