.div-principale-admin-code-promo {
  display: flex;
  flex-direction: row;
  margin: 1em 0 0 0;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }

  .secondary-div {
    width: 50%;
    @media (max-width: 1100px) {
      width: 100%;
    }
    .h1{
      margin: 1em 0 0 2em;
    }
    .secondary-div-sub {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5em 5em 0 5em;
      @media (max-width: 1100px) {
        align-items: center;
        margin: 0 1em 0 1em;
      }
    }
  }

  .third-div {
    width: 50%;
    margin: 2em 0 0 5em;
    @media (max-width: 1100px) {
      width: 100%;
      margin: 3em 1em 3em 3em;
    }
  }
}