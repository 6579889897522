.body-erreur-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100vh;
  background-image: url("../../Images/Rectangle.png");
}
@media (max-width: 600px) {
  .body-erreur-page {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .body-erreur-page .container-left-erreur #prise {
    display: none;
  }
}
.body-erreur-page .container-right-erreur {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 600px) {
  .body-erreur-page .container-right-erreur h3 {
    margin: 1em 1em 2em 2.5em;
  }
}
.body-erreur-page .erreur404 {
  margin: 0;
  padding: 0;
  font-size: 5rem;
}
.body-erreur-page .btn-back-to-home {
  padding: 1em;
}

