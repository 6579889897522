.div-input-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0.4em 0;
  width: 95%;
}
.div-input-register label {
  text-align: start;
}
.div-input-register .div-error-message-register {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;
  margin: 0.5em 0 0 0;
}
.div-input-register .div-error-message-register p {
  margin: 0;
}
.div-input-register .div-error-message-register #icon-infos-error {
  margin: 0 0.5em 0 0;
  color: red;
}
.div-input-register .div-password {
  position: relative;
}
.div-input-register .div-password #icon-eye-see-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.body-element-register {
  padding: 2em 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f2f0ec;
  height: 90vh;
}
.body-element-register p {
  padding: 0;
  margin: 0 0 0.5em 0;
}
.body-element-register input {
  margin: 0;
  width: 95%;
}
@media (max-width: 350px) {
  .body-element-register input {
    width: 92%;
  }
}
.body-element-register .btn-genre {
  padding: 0.7em;
  width: auto;
  margin: 1em 1em 2em 0;
}
.body-element-register .div-text-login {
  margin: 0 0 2em 0;
}
.body-element-register .div-label-register {
  display: flex;
  align-items: flex-start;
  justify-content: start;
}

.div-login-right {
  display: flex;
  justify-content: center;
  width: 50%;
  max-height: 100vh;
  object-fit: cover;
}
.div-login-right img {
  width: 100%;
  border-radius: 5px 5px 5px 0px;
}
@media (max-width: 1300px) {
  .div-login-right {
    display: none;
  }
}

.div-login-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-login-left .btn-google-login {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  width: 30%;
  margin: 1.75em 0 1.75em 0;
  color: #000;
}
@media (max-width: 1300px) {
  .div-login-left .btn-google-login {
    width: 60%;
  }
}
@media (min-width: 500px) and (max-width: 900px) {
  .div-login-left .btn-google-login {
    width: 70%;
  }
}
@media (min-width: 901px) and (max-width: 1300px) {
  .div-login-left .btn-google-login {
    width: 50%;
  }
}
@media (max-width: 499px) {
  .div-login-left {
    width: 100%;
  }
}
.div-login-left .div-connexion {
  padding: 1em;
  display: flex;
  flex-direction: column;
}
.div-login-left .div-connexion label {
  text-align: start;
}
.div-login-left .div-connexion .div-password {
  position: relative;
}
.div-login-left .div-connexion .div-password .input-login {
  margin: 0;
}
.div-login-left .div-connexion .div-password #icon-eye-see-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.body-page-addAdresse {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1300px) {
  .body-page-addAdresse {
    flex-direction: column;
  }
}
.body-page-addAdresse .formulaire-ajout-adresse {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 3em 15em;
}
@media (max-width: 1300px) {
  .body-page-addAdresse .formulaire-ajout-adresse {
    align-items: center;
    margin: 0 0 2em 0;
    justify-content: center;
  }
}

.formulaire-en-colonne {
  display: flex;
  flex-direction: column;
}
.formulaire-en-colonne .div-error-message-register {
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  padding: 0;
  color: red;
}
.formulaire-en-colonne .div-error-message-register p {
  margin: 0 0 0.5em 0.5em;
}
.formulaire-en-colonne .div-error-message-register #icon-infos-error {
  margin: 0 0.5em 0.5em 0.5em;
  color: red;
}
.formulaire-en-colonne input {
  margin: 0 0 0.5em 0;
}

#form1 {
  margin: 2em 0 2em;
}

