@import "../../styles/colors.scss";

.main-div-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 2em;
  .header-filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.25em 0.5em;
  }
  .categories-div {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    border: 1.5px solid $color-border;
    min-width: 15em;
    margin: 0 0 0.6em 0;
    :hover {
      cursor: pointer;
    }
    .header-categories {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 0.5em;
    }
    .div-body-filter-card{
      .div-une-pierre{
        input{
          width: 14px;
          height: 14px;
        }
      }
    }
    .radio-trie-div{
      margin: 0 0 0.5em 0;
      input{
        margin: 0 0.8em 0 1em;
      }
    }
  }
  .div-max-and-min-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      max-width: 4em;
      margin: 0 1.5em 0 1.5em;
      border-radius: 5px;
      padding: 0.20em;
    }
  }
  .slider-div{
    margin: 1em;
    display: flex;
    flex-direction: column;
    #btn-valider-filtre-prix{
      margin: 1em 0 0 0;
    }
  }
  span {
    margin: 0.5em 2em 1em 0;
    min-width: 7em;
  }
  a {
    padding-bottom: 1em;
  }
  .icon-categories {
    cursor: pointer;
  }
}
