.main-panier-page {
  display: flex;
  flex-direction: row;
  margin: 0 0 1em 0;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1em 1em 0;
  min-width: 35em;
  max-width: 35em;
  @media (max-width: 1330px) {
    padding: 0;
  }
  @media (max-width: 930px) {
    justify-content: center;
  }


  .card-panier-div-name-and-price-mobile {
    display: flex;
    align-items: center;
  }

  .card-panier-mobile-container-up {
    @media (max-width: 930px) {
      display: flex;
      flex-direction: row;
    }
  }

  @media (max-width: 930px) {
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
  }

  .card-panier-mobile-container-up-left {
    @media (max-width: 930px) {
      img {
        height: 220px;
        width: 220px;
      }
    }
  }

  .card-panier-mobile-container-up-right {
    display: flex;
    flex-direction: row;
    margin: 0 1.5em 0 1em;
    align-items: center;
    @media (max-width: 930px) {
      margin: 1em 2em 0 2em;
      justify-content: center;
      width: 100%;
    }

    a {
      margin: 0.2em 0 0 2em;
    }

    select {
      margin: 0 1em 0 1em;
    }
  }

  .custom-size {
    font-size: 1.5em; /* Adjust the size as needed */
  }

  img {
    border-radius: 5px;
  }

  .name-element {
    max-width: 20em;
    min-width: 14em;
    margin: 0 2em 0 0;
    @media (max-width: 1330px) {
      margin: 0;
      min-width: 11em;
      max-width: 11em;
      display: flex;
      align-items: start;
    }
  }

  select {
    margin: 0 1em 0 1em;
  }
}