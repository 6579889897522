.lmj-footer {
  background-color: #22242a;
  color: #ededee;
  padding: 0 0 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  width: 100%;
  position: relative;
  z-index: 1;
}

.div-main-elements-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
@media (max-width: 900px) {
  .div-main-elements-footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.div-main-elements-footer p {
  font-size: 1.1em;
  cursor: pointer;
}
@media (max-width: 900px) {
  .div-main-elements-footer p {
    font-size: 1em;
  }
}
.div-main-elements-footer .div-menu-plus-informations-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.div-main-elements-footer .div-menu-plus-informations-footer .div-menu-footer {
  padding: 2em;
}
@media (max-width: 900px) {
  .div-main-elements-footer .div-menu-plus-informations-footer .div-menu-footer {
    padding: 0 3em 0 0;
    justify-content: center;
    width: 30%;
  }
}
.div-main-elements-footer .div-menu-plus-informations-footer .div-informations-footer {
  padding: 2em;
}
@media (max-width: 900px) {
  .div-main-elements-footer .div-menu-plus-informations-footer .div-informations-footer {
    padding: 0;
    width: 30%;
  }
}
.div-main-elements-footer .lmj-footer-elem {
  margin-bottom: 10px;
}
.div-main-elements-footer .footer-text {
  max-width: 30%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 900px) {
  .div-main-elements-footer .footer-text {
    max-width: 90%;
    text-align: center;
  }
}
.div-main-elements-footer .footer-text h2 {
  margin: 0;
}

.icon-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0 1em 0;
}
.icon-socials .iconSocial:hover {
  cursor: pointer;
}

.iconSocial {
  height: 1.5em;
  margin: 1em 1em 0 1em;
}

.liens-footer::after {
  content: "";
  display: block;
  width: 0;
  height: 4px;
  background-color: #4C6945;
  transition: width 0.3s ease-in-out;
}

.liens-footer:hover::after {
  width: 100%;
}

