@import "../../styles/colors.scss";
@import "../../styles/index.scss";
@import "../Register/Register";

.div-client-plus-password {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 15em;
  @media (max-width: 1300px) {
    flex-direction: column;
    margin: 0;
  }
}

.div-infos-client {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 3em 0 3em 0;

  .btn-genre {
    padding: 1em;
    width: auto;
  }
}

.div-main-profil {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin: 5em 0 0 0;
  @media (max-width: 1300px) {
    flex-direction: column;
    margin: 2em 0 0 0;
  }
}

.div-infos-perso {
  display: flex;
  flex-direction: column;
}

.div-infos-perso > button {
  margin: 2em 0 0 0;
  padding: 0.5em;
}

.radio-btn-genre {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.div-infos-client > img {
  margin: 0 0 2em 0;
}

.image-profil-modifier {
  display: flex;
  flex-direction: row;
}

.image-profil-modifier > input {
  display: flex;
  align-items: end;
  display: none;
}

.div-change-password {
  display: flex;
  flex-direction: column;
  margin: 3em 0 0 3em;

  .div-password {
    position: relative;
    margin: 1.2em 0 1.2em 0;

    .input-login {
      margin: 0;
    }

    #icon-eye-see-password {
      @include positioningEyeIcon();
    }
  }

  button {
    margin: 0.5em 0 0 0;
    padding: 0.5em;
  }

  input {
    margin: 0.8em 0 1.2em 0;
  }

  @media (max-width: 1300px) {
    justify-content: center;
    align-items: center;
    margin: 2em 0 3em 0;
  }
}
