.div-une-commande {
  margin: 2em;
  padding: 2em;
  border: 2px solid black;
}

.div-commandes {
  flex-wrap: wrap;
  width: 70%;
  @media (max-width: 1300px) {
    width: 100%;
  }
  margin: 0 0 2em 0;
}
