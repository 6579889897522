@import "../../styles/colors.scss";

.lmj-banner {
  background-color: #15202b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 2em 0;
  position: relative;
  top: 0;
  z-index: 2;
  height: 8vh;
  color: #ffffff;

  .banner-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;

    #banner-title {
      @media (min-width: 941px) {
        display: none;
      }
      @media (max-width: 940px) {
        display: block;
        position: relative;
        right: 30vw;
        margin: 1.25em 0 0 0;
      }
      @media (max-width: 500px) {
        display: block;
        width: 2em;
        margin: 0.7em 0 0.1em 0;
      }
    }

    #icon-menu-mobile {
      display: none;
      @media (max-width: 940px) {
        display: block;
        position: relative;
        top: 1vh;
        left: 30vw;
      }
    }
  }

  .second-barre-banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1.25em 0 0 0;

    .div-liens-pc {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin: 0;

      .lmj-title::after {
        content: "";
        display: block;
        width: 0;
        height: 4px;
        background-color: #4C6945;
        transition: width 0.3s ease-in-out;
      }

      .lmj-title:hover::after {
        width: 100%;
      }
    }

    @media (max-width: 940px) {
      display: none;
    }

    .icon-socials-banner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5em 1em 0;

      .iconSocial:hover {
        cursor: pointer;
      }

      @media (max-width: 1330px) {
        display: none;
      }
    }

    .icon-header {
      padding: 0 0 0 0.2em;
      position: relative;

      .span-nb-articles {
        background-color: #DDD1B1;
        color: #000000;
        border-radius: 10em;
        padding: 1px 6px;
        position: absolute;
        top: -40%;
        right: -20%;
      }
    }
  }
}

.mobile-banner {
  display: none;
  position: relative;
  transform: translate(-100%);
  @media (max-width: 1330px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #15202b;
    color: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    transform: translate(-100%);
    z-index: 1999;
    transition: all 0.5s;
  }

  #menu-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5em;
  }

  .div-mobile-liens-page-banner {
    display: flex;
    flex-direction: column;
    margin: 2em 1.25em 2em 0;
    text-align: center;

    a {
      margin: 0.5em;
    }
  }

  .icon-header-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-socials-banner-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

.lmj-title {
  padding-left: 32px;
  font-size: 1.25rem;
  cursor: pointer;
}

#icone_panier {
  margin: 0 0 0 2.2em;
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(323deg) brightness(104%) contrast(101%);
}

#icon-user-a {
  cursor: pointer;
  filter: invert(0%) sepia(3%) saturate(7487%) hue-rotate(294deg) brightness(104%) contrast(99%);
}

// Style pc et mobile

.name-under-basket-icon-navbar {
  font-size: 0.8em;
  padding: 0;
  margin: 0.3em 0 0 3em;
  align-items: center
}

.div-icon-login-navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 0 0 5em;

  .name-under-login-icon-navbar {
    font-size: 0.8em;
    padding: 0;
    margin: 0.3em 0 0 0;
    text-align: center;
  }
}

.div-icon-basket-navbar {
  display: flex;
  justify-content: center;
  flex-direction: column;
}