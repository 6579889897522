@import "../../styles/colors.scss";
@import "../../styles/index.scss";

.body-page-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 0 2.5em 0;
  font-size: clamp(16px, 5vw, 24px);

  .input-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .input-contact > input {
    padding: 0.8em;
    border: 2px solid $color-border;
    width: 30em;
    border-radius: 5px;
    margin: 0 0 2em 0;
    @media (max-width: 570px) {
      max-width: 70%;
    }
  }

  textarea {
    border: 2px solid $color-border;
    border-radius: 5px;
    height: 25vh;
    padding: 0.5em;
    font-family: "Helvetica Neue";
    font-weight: 500;
    font-size: 1em;
    width: 95%;
    @media (max-width: 570px) {
      max-width: 70%;
    }
  }

  .images {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 570px) {
      #img_cactus_contact_page {
        display: none;
      }
    }
    @media (max-width: 570px) {
      #img_dame {
        display: none;
      }
    }
  }

  #btn_contact {
    margin: 1em 0 0 0;
    padding: 1em;
    width: 100%;
    @media (max-width: 570px) {
      max-width: 78%;
    }
  }
}

@media (max-width: 1100px) {
  .body-page-contact {
    flex-direction: column-reverse;
  }
}
