.div-carrousel-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 1em 0 0 0;

  img {
    border-radius: 7px;
    @media (max-width: 1000px) {
      margin: 0 1em 0 0;
    }
    @media (min-width: 1001px) {
      margin: 0 0 1em 0;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: row;
  }
  @media (min-width: 1001px) {
    padding: 1em 5em 0 1em;
  }
}