.body-erreur-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100vh;
  background-image: url("../../Images/Rectangle.png");
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .container-left-erreur {
    #prise{
      @media (max-width: 600px){
        display: none;
      }
    }
  }
  .container-right-erreur {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3{
      @media (max-width: 600px) {
        margin: 1em 1em 2em 2.5em;
      }
    }
  }
  .erreur404 {
    margin: 0;
    padding: 0;
    font-size: 5rem;
  }
  .btn-back-to-home{
    padding: 1em;
  }
}
