.body-element-panier {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 5em 0 5em 0;
}
@media (max-width: 1330px) {
  .body-element-panier {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.body-element-panier .panier-check-out {
  position: sticky;
  top: 9%;
  padding: 0 0 0 5em;
  height: 100%;
  width: 25%;
}
@media (max-width: 1330px) {
  .body-element-panier .panier-check-out {
    padding: 0;
    width: 90%;
  }
}
.body-element-panier .panier-check-out button {
  width: 100%;
}
.body-element-panier .panier-check-out .div-summary-command {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.body-element-panier .div-1-element-panier {
  display: flex;
  flex-direction: row;
  margin: 0 0 3em 0;
  border: 2px solid black;
  padding: 3em;
}
.body-element-panier .div-1-element-panier > select {
  margin: 0 3em 0 1em;
}
.body-element-panier .div-panier-vide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em;
  height: 35vh;
}
.body-element-panier .panier {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 2em 0;
  margin: 0 0 0 2em;
}
@media (max-width: 1330px) {
  .body-element-panier .panier {
    width: 95%;
    margin: 0;
  }
}
.body-element-panier .panier .div-main-code-promo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0 0 0;
}
@media (max-width: 930px) {
  .body-element-panier .panier .div-main-code-promo {
    flex-direction: column;
  }
}
.body-element-panier .panier .div-main-code-promo .div-code-promo-left {
  margin: 1em 0 0 0;
  max-width: 25em;
}
@media (max-width: 1330px) {
  .body-element-panier .panier .div-main-code-promo .div-code-promo-left {
    margin: 1em 0 1.5em 0;
  }
}
.body-element-panier .panier .div-main-code-promo .div-code-promo-left :hover {
  cursor: pointer;
}
.body-element-panier .panier .div-main-code-promo .div-code-promo-left #span-code-promo {
  padding: 1em 1em 0 1em;
}
.body-element-panier .panier .div-main-code-promo .div-code-promo-right {
  max-height: 1em;
}
@media (max-width: 1330px) {
  .body-element-panier .panier .div-main-code-promo .div-code-promo-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.body-element-panier .panier .div-main-code-promo .div-code-promo-right button {
  margin: 0 0 0 0.25em;
}
.body-element-panier .panier #title-panier {
  padding: 0 0 0 1em;
}
.body-element-panier #btn-vider-panier {
  margin: 0 0 4em 0;
}
.body-element-panier select {
  border-radius: 10px;
  padding: 0.5em;
}
.body-element-panier .div-panier-vide h2 {
  min-width: 7em;
  text-align: center;
}

