.main-div-details-product-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  input, textarea {
    width: 30%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .div-images-produit-details-admin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 220px;
      height: 220px;
      margin: 10px;
    }

    .secondary-div-images-details-product-admin {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 10px;
    }
  }
}

.example-of-others-products {
  @media (max-width: 1490px) {
    display: flex;
    flex-wrap: wrap;
  }
}