@import "../../styles/colors.scss";

.div-text-aPropos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 300;
  padding: 0 0 2em 0;
  @media (max-width: 1625px) {
    #fleche {
      display: none;
    }
  }

  .a-propos-texte {
    margin: 0 0 0 3em;
    display: flex;
    flex-direction: row;
    @media (max-width: 1330px) {
      flex-direction: column;
      margin: 1em;
    }

    #text-1, #text-2, #text-3, #text-4, #text-5, #text-6, #text-7, #text-8 {
      width: 100%;
    }

    .container-right-text-a-propos {
      margin: 5em 0 0 5em;

      p {
        margin: 5em 0 0 0;
      }

      @media (max-width: 1330px) {
        margin: 0;
      }
    }

    .container-left-text-a-propos {
      p {
        margin: 5em 0 0 0;
      }
    }

    .div-fleches {
      display: flex;
      flex-direction: column;

      img {
        margin: 8em 0 0 3em;
      }
    }
  }

  .a-propos-div-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
