@import "../../styles/colors.scss";

.body-page-favoris {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin: 5em 0 0 0;
  @media (max-width: 1300px) {
    flex-direction: column;
    margin: 2em 0 0 0;
  }
}

.container-right-favoris {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 70%;
  @media (max-width: 1300px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}
