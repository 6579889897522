.status-annule {
  background-color: #ff3f34;
  color: white;
}

.status-livre {
  background-color: #4cd137;
  color: white;
}

.status-livraison {
  background-color: #fbc531;
  color: white;
}

.div-main-admin-home {
  height: 80dvh;

  .div-button-admin-home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @media (max-width: 500px) {
      flex-direction: column;
    }

    button {
      margin: 0 1em 0 0;
      @media (max-width: 500px) {
        max-width: 90%;
        margin: 0 0 1em 0;
      }
    }
  }
}