.div-principale-admin-code-promo {
  display: flex;
  flex-direction: row;
  margin: 1em 0 0 0;
}
@media (max-width: 1100px) {
  .div-principale-admin-code-promo {
    flex-direction: column;
    align-items: center;
  }
}
.div-principale-admin-code-promo .secondary-div {
  width: 50%;
}
@media (max-width: 1100px) {
  .div-principale-admin-code-promo .secondary-div {
    width: 100%;
  }
}
.div-principale-admin-code-promo .secondary-div .h1 {
  margin: 1em 0 0 2em;
}
.div-principale-admin-code-promo .secondary-div .secondary-div-sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5em 5em 0 5em;
}
@media (max-width: 1100px) {
  .div-principale-admin-code-promo .secondary-div .secondary-div-sub {
    align-items: center;
    margin: 0 1em 0 1em;
  }
}
.div-principale-admin-code-promo .third-div {
  width: 50%;
  margin: 2em 0 0 5em;
}
@media (max-width: 1100px) {
  .div-principale-admin-code-promo .third-div {
    width: 100%;
    margin: 3em 1em 3em 3em;
  }
}

