@charset "UTF-8";
body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img,
button,
select,
option {
  cursor: pointer;
}

button {
  border-radius: 5px;
  background-color: #22242A;
  color: #ffffff;
  padding: 1em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.425s ease-in-out;
}

.overflow-hidden {
  overflow-y: hidden;
}

button:hover:hover {
  background-color: #ded2b2;
  color: #000000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

h2 {
  font-weight: bold;
}

a:link {
  color: #000000;
  text-decoration: none;
}

a:visited {
  color: #000000;
  text-decoration: none;
}

html {
  overflow-x: hidden;
}

.navbar {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 20em;
  margin: 0 2em 0 0;
  background-color: #22242a;
  color: white;
  border-radius: 0 10px 10px 0;
}
@media (max-width: 450px) {
  .navbar {
    margin: 0;
    max-width: 30em;
    min-width: 5em;
  }
}
.navbar button {
  display: flex;
  flex-direction: column;
}
.navbar #icon-navbar {
  margin: 0 1em 0 0;
}
@media (max-width: 1300px) {
  .navbar {
    margin: 0;
  }
}

.navbar > button {
  margin: 0 0 0 0;
  width: 80%;
  background-color: transparent; /* ou background-color: initial; */
  border: 1px solid #000; /* Ajoutez une bordure si nécessaire */
  color: #000; /* Couleur du texte */
  padding: 10px 15px; /* Ajoutez un rembourrage pour le style du bouton */
  text-decoration: none; /* Supprimez la soulignement du texte si le bouton est utilisé comme lien */
  display: inline-block; /* Permet au bouton de se comporter comme un élément en ligne avec des propriétés de bloc */
  cursor: pointer;
  border: none;
  font-size: 1.25em;
  color: white;
}

.navbar > button:hover {
  color: white; /* Change la couleur du texte au survol */
  background-color: #394049; /* Change la couleur de fond au survol */
}

.navbar > hr {
  width: 80%;
}

.navbar > h3 {
  margin: 1em 0 3em 0;
}

