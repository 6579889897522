body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img,
button,
select,
option {
  cursor: pointer;
}

button {
  border-radius: 5px;
  background-color: #22242A;
  color: #ffffff;
  padding: 1em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.425s ease-in-out;
}

.overflow-hidden {
  overflow-y: hidden;
}

button:hover:hover {
  background-color: #ded2b2;
  color: #000000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

h2 {
  font-weight: bold;
}

a:link {
  color: #000000;
  text-decoration: none;
}

a:visited {
  color: #000000;
  text-decoration: none;
}

html {
  overflow-x: hidden;
}

.div-input-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0.4em 0;
  width: 95%;
}
.div-input-register label {
  text-align: start;
}
.div-input-register .div-error-message-register {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;
  margin: 0.5em 0 0 0;
}
.div-input-register .div-error-message-register p {
  margin: 0;
}
.div-input-register .div-error-message-register #icon-infos-error {
  margin: 0 0.5em 0 0;
  color: red;
}
.div-input-register .div-password {
  position: relative;
}
.div-input-register .div-password #icon-eye-see-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.body-element-register {
  padding: 2em 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f2f0ec;
  height: 90vh;
}
.body-element-register p {
  padding: 0;
  margin: 0 0 0.5em 0;
}
.body-element-register input {
  margin: 0;
  width: 95%;
}
@media (max-width: 350px) {
  .body-element-register input {
    width: 92%;
  }
}
.body-element-register .btn-genre {
  padding: 0.7em;
  width: auto;
  margin: 1em 1em 2em 0;
}
.body-element-register .div-text-login {
  margin: 0 0 2em 0;
}
.body-element-register .div-label-register {
  display: flex;
  align-items: flex-start;
  justify-content: start;
}

.div-login-right {
  display: flex;
  justify-content: center;
  width: 50%;
  max-height: 100vh;
  object-fit: cover;
}
.div-login-right img {
  width: 100%;
  border-radius: 5px 5px 5px 0px;
}
@media (max-width: 1300px) {
  .div-login-right {
    display: none;
  }
}

.div-login-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-login-left .btn-google-login {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  width: 30%;
  margin: 1.75em 0 1.75em 0;
  color: #000;
}
@media (max-width: 1300px) {
  .div-login-left .btn-google-login {
    width: 60%;
  }
}
@media (min-width: 500px) and (max-width: 900px) {
  .div-login-left .btn-google-login {
    width: 70%;
  }
}
@media (min-width: 901px) and (max-width: 1300px) {
  .div-login-left .btn-google-login {
    width: 50%;
  }
}
@media (max-width: 499px) {
  .div-login-left {
    width: 100%;
  }
}
.div-login-left .div-connexion {
  padding: 1em;
  display: flex;
  flex-direction: column;
}
.div-login-left .div-connexion label {
  text-align: start;
}
.div-login-left .div-connexion .div-password {
  position: relative;
}
.div-login-left .div-connexion .div-password .input-login {
  margin: 0;
}
.div-login-left .div-connexion .div-password #icon-eye-see-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.div-client-plus-password {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 15em;
}
@media (max-width: 1300px) {
  .div-client-plus-password {
    flex-direction: column;
    margin: 0;
  }
}

.div-infos-client {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 3em 0 3em 0;
}
.div-infos-client .btn-genre {
  padding: 1em;
  width: auto;
}

.div-main-profil {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin: 5em 0 0 0;
}
@media (max-width: 1300px) {
  .div-main-profil {
    flex-direction: column;
    margin: 2em 0 0 0;
  }
}

.div-infos-perso {
  display: flex;
  flex-direction: column;
}

.div-infos-perso > button {
  margin: 2em 0 0 0;
  padding: 0.5em;
}

.radio-btn-genre {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.div-infos-client > img {
  margin: 0 0 2em 0;
}

.image-profil-modifier {
  display: flex;
  flex-direction: row;
}

.image-profil-modifier > input {
  display: flex;
  align-items: end;
  display: none;
}

.div-change-password {
  display: flex;
  flex-direction: column;
  margin: 3em 0 0 3em;
}
.div-change-password .div-password {
  position: relative;
  margin: 1.2em 0 1.2em 0;
}
.div-change-password .div-password .input-login {
  margin: 0;
}
.div-change-password .div-password #icon-eye-see-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.div-change-password button {
  margin: 0.5em 0 0 0;
  padding: 0.5em;
}
.div-change-password input {
  margin: 0.8em 0 1.2em 0;
}
@media (max-width: 1300px) {
  .div-change-password {
    justify-content: center;
    align-items: center;
    margin: 2em 0 3em 0;
  }
}

