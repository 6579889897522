@import "../../styles/colors.scss";
@import "../../styles/index.scss";


#xmark-filtre-mobile {
  margin: 0.2em 0 0 0;
  position: relative;
  top: 11%;
  left: 95%;
}

.categories-div-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    margin: 0.5em 0 2em 0;
    padding: 0.5em;
  }
}

.lmj-shopping-list {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;

  .div-recherche-produit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 10em;

    .search-bar-class {
      font-size: 1.5em;
      background-color: #282e32;
      padding: 0.3em;
      border-radius: 0 9px 9px 0;
      margin: 0 5em 0 0;
      border: 1px solid black;
      color: #6e7a85;

      :hover {
        cursor: pointer;
      }
    }

    input {
      width: 27%;
      border-radius: 9px 0 0 9px;
      padding: 0.5em;
      font-size: 1.05rem;
    }
  }

  .div-button-filtre-mobile-vue {
    display: none;
    @media (max-width: 850px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 1em 0 0 0;
      button {
        width: 70%;
      }
    }
  }

  .div-categories-plus-products-list {
    display: flex;
    flex-direction: column;
    margin: 0 0 2em 0;

    .categories-and-filtre-and-trie {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2em 0 0 2em;
      position: sticky;
      @media (max-width: 850px) {
        display: none;
      }

      a {
        margin: 0 0.75em 0 0.75em;
        font-size: 1.25rem;
        transition: 0.25s ease-in-out;

        &:hover {
          cursor: pointer;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .container-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hr-collections {
    border-style: solid; /* Style de la ligne */
  }
}

.lmj-plant-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  @media (max-width: 1300px) {
    margin: 2em 0 0 0;
  }

  .div-product {
    :hover {
      box-shadow: #22242a;
    }
  }

  .button-add-to-basket-plus-heart {
    display: flex;
    align-items: center;
  }
}

.loader-div-shopping-list {
  margin: auto;
}

#btn-ajouter {
  margin: 0 0 0 0.7em;
  width: 72%;
  padding: 0.25em;
}

#btn-details-plant {
  margin: 0 0 0 1em;
}

.hr-custom {
  margin: 0 2em 0 0;
  padding: 0;
  @media (max-width: 850px) {
    margin: 0 0 1em 0;
  }
}

.hr-horizontal-categories {
  margin: 0 2em 0 0;
  padding: 0;
  width: 100%;
}
