@import "../../styles/colors.scss";
@import "../Register/Register";

.body-page-addAdresse {
  display: flex;
  flex-direction: row;
  @media (max-width: 1300px) {
    flex-direction: column;
  }

  .formulaire-ajout-adresse {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 3em 15em;
    @media (max-width: 1300px) {
      align-items: center;
      margin: 0 0 2em 0;
      justify-content: center;
    }
  }
}

.formulaire-en-colonne {
  display: flex;
  flex-direction: column;

  .div-error-message-register {
    display: flex;
    flex-direction: row;
    font-size: 0.9em;
    padding: 0;
    color: red;

    p {
      margin: 0 0 0.5em 0.5em;
    }

    #icon-infos-error {
      margin: 0 0.5em 0.5em 0.5em;
      color: red;
    }
  }

  input {
    margin: 0 0 0.5em 0;
  }
}

#form1 {
  margin: 2em 0 2em;
}
