@import "../../styles/colors.scss";

.card-adresse-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 2em 0;
  width: 25em;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  @media (max-width: 440px) {
    max-width: 90%;
  }
}

.container-left-adresses,
.container-right-adresses {
  display: flex;
  flex-direction: column;
  margin: 0.8em;

  svg {
    font-size: 1.5em;
  }

  #btn-modifer-adresse {
    padding: 0.5em;
    margin: 0.75em 0 0;
  }

  h4 {
    margin: 0.25em 0 0 0;
  }

  .div-ville-cp {
    display: flex;
    flex-direction: row;
  }
}
