@import "../../styles/colors.scss";

#vertical-hr {
  border: 0;
  border-left: 1px solid $color-border;
  min-height: 12em;
  max-height: 30em;
  margin: 10px;
  @media (max-width: 900px) {
    display: none;
  }
}

.div-section-admin-details-commande {
  margin: 2em 2em 3em 5em;
  @media (max-width: 1300px) {
    margin: 2em 0 5em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

#vertical-hr-admin {
  border: 0;
  height: 70vh;
  border-left: 1px solid $color-border;
  margin: 10px;
  @media (max-width: 1300px) {
    height: 0;
    border-bottom: 1px solid $color-border;
  }
}

.div-prix-details-commande {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  flex-direction: row;
  margin: 0 0 0 1em;

  @media (max-width: 400px) {
    margin: 0;
  }

  #prix-total-details-commande {
    border-bottom: 2px solid red;
    padding: 0 0 0.1em 0;
  }

  p {
    margin: 0.25em;
  }
}

.div-buttons-actions-details-commande {
  display: flex;
  align-items: center;
  margin: 0 0 2em 0;
  max-width: 90%;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100%;
  }

  button {
    margin: 0 1em 0 0;

    #icon-buttons-actions-details-commande {
      margin: 0 0.5em 0 0;
    }
  }
}

.div-commandes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .div-produit-details-commande {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1.5px solid $color-border;
    border-radius: 5px;
    margin: 0 0 0.75em 0;
    width: 95%;
    flex-wrap: wrap;
    @media (max-width: 590px) {
      flex-direction: column;
    }

    .div-images-details-commande {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      @media (max-width: 590px) {
        width: 100%;
        height: 100%;
      }
    }

    .div-tertiaire-produit-details-commande {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 70%;
      @media (max-width: 590px) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.div-principal-detailsCommande {
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .div-details-commande-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    @media (min-width: 1px) and (max-width: 1299px) {
      width: 100%;
    }

    .div-recap-details-commande {
      margin: 2em 0 5em 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 70%;
      @media (max-width: 1300px) {
        width: 90%;
      }
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }

  .div-secondaire-details-commande {
    display: flex;
    flex-direction: row;
    margin: 0 0 1em 0;
    border: 1.5px solid $color-border;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @media (max-width: 900px) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 95%;
    }

    .div-recap-commande-1 {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      padding: 10px;
      min-width: 25%;

      .div-status-commande {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 0.5em 0;

        p {
          border-radius: 5px;
          padding: 0.25em;
          margin: 0;
        }
      }

      p {
        margin: 0 3em 0.5em 0;
      }
    }
  }
}
