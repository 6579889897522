@import "../Register/Register";

.div-main-checkout-delivery {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5em 1em 1em 1em;
  max-width: 100%;
  @media (max-width: 1299px) {
    flex-direction: column;
  }

  .div-left-main-div-checkout-delivery {
    display: flex;
    flex-direction: column;
    width: 30%;
    @media (max-width: 1300px) {
      margin: 0 0 3em 0;
      width: 80%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }

    .panel-relay-point {
      border: 2px solid #ededee;
      padding: 1em;
      height: 500px;
      overflow: auto;
      overflow-y: scroll;
      margin: 10px;
      border-radius: 5px;
      @media (max-width: 1300px) {
        margin: 0;
        padding: 5px;
      }
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
  }

  .input-code-postal-relais {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 0 0.5em 0;
    max-width: 57%;

    #icon-input-code-postal-relais {
      @include positioningEyeIcon();
      top: 40%;
    }
  }
}