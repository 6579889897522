@import "../../styles/colors.scss";

.div-going-back {
  @media (max-width: 1000px) {
    display: none;
  }
}

.example-of-others-products {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.div-banderole {
  width: 100%;
  background-color: #ae445a;
  margin-top: 5em;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 2em 0 0 0;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
}

.secondary-div-infos-sur-marque {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  flex-direction: column;
  background-color: #ae445a;
  color: #fff;
  border-radius: 20px;
  @media (max-width: 800px) {
    width: 85%;
  }

  .third-div-infos-sur-marque {
    width: 80%;
    cursor: pointer;
    @media (max-width: 800px) {
      width: 85%;
    }
  }
}

.bodyElementDetails {
  background-color: $background-color;
  flex-direction: row;


  .loader-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15rem;
  }

  .return-to-collection {
    width: 7em;

    a {
      margin: 2em;
    }
  }

  .container-left-details {
    display: flex;
    border-radius: 50px;
    height: 80vh;

    #image_produit {
      @media (min-width: 1000px) {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0;
    }
  }

  .div-container-left-plus-right {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0 0 2em 0;
  }
}

.details-list {
  display: flex;
  flex-direction: column;
  width: 35%;

  .description {
    max-width: 35rem;
  }

  #button_add_to_cart {
    margin: 0.25em 0 0 0;
    width: 100%;
  }

  #div-select_size_product {
    width: 100%;
    margin: 3em 0 0 0;

    .select-taille-bracelet {
      padding: 0.7em;
      margin: 0 0 0.2em 0;
      border-radius: 4px;
      width: 100%;

      &:focus {
        border: 2px solid #6e9afc;
      }

      option {
        padding: 0.5em;
        margin: 0.5em;
      }
    }
  }
}

.return {
  padding: 1em 0 0 2em;
}

img {
  border-radius: 30px;
}

@media (max-width: 1000px) {
  .bodyElementDetails {
    padding: 0;

    .div-container-left-plus-right {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;

      .container-left-details {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;

        #image_produit {
          width: 100%;
          height: 100%;
          object-fit: contain;
          margin: 0;
          border-radius: 0;
        }
      }

      .details-list {
        padding: 0 0 2em 0;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
